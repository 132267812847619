import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import * as styles from "./articles.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import RightArrow from "./rightArrow"
import LeftArrow from "./leftArrow"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Box, Paper, Typography, useTheme } from "@mui/material"
import { differenceInDays, format, formatDistance, isFuture } from "date-fns"
import { imageUrlFor } from "../../../blog/lib/image-url"
import { buildImageObj, cn, getArticleUrl } from "../../../blog/lib/helpers"
import MetaAuthorIcon from "../../../images/metaAuthorIcon.svg"
import MetaCalendarIcon from "../../../images/metaCalendarIcon.svg"
import MetaPartitionIcon from "../../../images/metaPartitionIcon.svg"
import PortableText from "../../../blog/components/portableText"

const getAuthorsString = authors => {
  let authorsString = ""
  authors.forEach(author => {
    authorsString.length === 0
      ? (authorsString += author.author.name)
      : (authorsString += "," + author.author.name)
  })
  return authorsString
}

var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  centerMode: true,
  centerPadding: "200px",
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  draggable: false,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        centerPadding: "160px",
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        centerPadding: "80px",
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 960,
      settings: {
        centerPadding: "50px",
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: "0px",
        slidesToShow: 1,
      },
    },
  ],
}

const ArticlesCarousel = ({ slideIndex, setSlideIndex }) => {
  const theme = useTheme()
  const slider = useRef()

  useEffect(() => {
    console.log(slideIndex)
    // slider.current.slickGoTo(slideIndex)
    if (slideIndex === -1) slider.current.slickPrev()
    else if (slideIndex === 1) slider.current.slickNext()
    setSlideIndex(0)
  }, [slideIndex])

  const articlesData = useStaticQuery(graphql`
    query ArticlesQuery {
      posts: allSanityArticle(
        limit: 8
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            mainImage {
              ...SanityImage
              alt
            }
            title
            _rawExcerpt
            slug {
              current
            }
            authors {
              _key
              author {
                image {
                  crop {
                    _key
                    _type
                    top
                    bottom
                    left
                    right
                  }
                  hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                  }
                  asset {
                    _id
                  }
                }
                name
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Box sx={{ marginTop: "40px", marginBottom: "20px" }}>
      <Slider className="styles.articlesCarousel" {...settings} ref={slider}>
        {articlesData.posts.edges
          .filter(edge => !isFuture(new Date(edge.node.publishedAt)))
          .map((articleData, index) => {
            console.log({ ...articleData.node })
            const article = { ...articleData.node }
            const link =
              article.publishedAt && article.slug
                ? getArticleUrl(article.publishedAt, article.slug.current)
                : "#"
            const description =
              article._rawExcerpt[0] &&
              article._rawExcerpt[0].children[0] &&
              article._rawExcerpt[0].children[0].text
                ? article._rawExcerpt[0].children[0].text
                : ""

            return article ? (
              <Box
                key={`index}`}
                sx={{
                  padding: "0 20px 20px",
                  overflow: "hidden",
                }}
              >
                <Box className={styles.articleCard}>
                  <Link to={link} className={styles.articleLink}>
                    {article.mainImage && article.mainImage.asset && (
                      <Paper
                        component="div"
                        sx={{
                          boxShadow: "none",
                          width: "100%",
                          aspectRatio: "4/3",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "15px",
                          borderRadius: 0,
                        }}
                        id="blog-image"
                      >
                        <img
                          src={imageUrlFor(buildImageObj(article.mainImage))
                            .width(800)
                            .height(800)
                            .fit("crop")
                            .auto("format")
                            .url()}
                          alt={article.mainImage.alt}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Paper>
                    )}
                    {article.authors && article.publishedAt && (
                      <Paper
                        component="div"
                        sx={{
                          boxShadow: "none",
                          p: "5px 0 0",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          padding: "0 15px",
                          [theme.breakpoints.down("md")]: {
                            padding: "0 10px",
                          },
                        }}
                      >
                        <img
                          src={MetaAuthorIcon}
                          alt="expand icon"
                          style={{
                            padding: 0,
                            margin: 0,
                            width: 18,
                            height: 18,
                          }}
                        />
                        <Typography
                          variant="string"
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: 1,
                            marginLeft: "5px",
                          }}
                        >
                          {getAuthorsString(article.authors)}
                        </Typography>
                        <img
                          src={MetaPartitionIcon}
                          alt="expand icon"
                          style={{
                            padding: 0,
                            margin: 0,
                            width: 8,
                            height: 25,
                            margin: "auto 5px",
                          }}
                        />
                        <img
                          src={MetaCalendarIcon}
                          alt="expand icon"
                          style={{
                            padding: 0,
                            margin: 0,
                            width: 18,
                            height: 18,
                          }}
                        />
                        <Typography
                          variant="string"
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: "14px",
                            fontWeight: 300,
                            lineHeight: 1,
                            marginLeft: "5px",
                          }}
                        >
                          {differenceInDays(
                            new Date(article.publishedAt),
                            new Date()
                          ) > 3
                            ? formatDistance(
                                new Date(article.publishedAt),
                                new Date()
                              )
                            : format(
                                new Date(article.publishedAt),
                                "dd MMM yyyy"
                              ).toUpperCase()}
                        </Typography>
                      </Paper>
                    )}
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 600,
                        marginTop: "10px",
                        marginBottom: "10px",
                        textAlign: "left",
                        padding: "0 15px",
                        [theme.breakpoints.down("md")]: {
                          fontSize: 16,
                          padding: "0 10px",
                        },
                      }}
                    >
                      {article.title}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: "16px",
                        lineHeight: "1.6",
                        padding: "0 15px",
                        color: "#4d4d4d",
                        [theme.breakpoints.down("md")]: {
                          padding: "0 10px",
                          fontSize: "14px",
                        },
                      }}
                    >
                      {description.length < 120
                        ? description
                        : description.substring(0, 120) + "..."}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            ) : (
              <></>
            )
          })}
      </Slider>
    </Box>
  )
}

export default ArticlesCarousel
