import React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import FaqExpandIcon from "../../../images/faqExpand.svg"
import FaqCollapseIcon from "../../../images/faqCollapse.svg"
import * as styles from "./faqs.module.css"
import { Paper, useTheme } from "@mui/material"

const FaqCard = ({ question, answer, resetExpanded, setResetExpanded }) => {
  const theme = useTheme()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  React.useEffect(() => {
    if (resetExpanded) {
      setExpanded(false)
      setResetExpanded(false)
    }
  }, [resetExpanded])

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          padding: "10px 30px",
          borderRadius: "8px !important",
          marginBottom: "13px !important",
          position: "relative",
          [theme.breakpoints.down("sm")]: {
            padding: "5px 15px",
          },
        }}
      >
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ padding: 0, width: "calc(100% - 50px)" }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "black",
              fontWeight: 600,
              textAlign: "left",
              width: "90%",
              [theme.breakpoints.down("sm")]: {
                fontSize: 14,
              },
            }}
          >
            {question}
          </Typography>
          <Paper
            component="div"
            className={styles.expandExpandIcon}
            sx={{
              boxShadow: "none",
              right: -5,
              transform: "translate(100%, -50%)",
              width: 32,
              height: 32,
              [theme.breakpoints.down("sm")]: {
                width: 28,
                height: 28,
              },
            }}
          >
            {expanded === "panel1" ? (
              <img src={FaqCollapseIcon} alt="collapse icon" />
            ) : (
              <img src={FaqExpandIcon} alt="expand icon" />
            )}
          </Paper>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0, width: "calc(100% - 10px)" }}>
          <Typography
            sx={{
              fontSize: 14,
              color: "rgba(136, 136, 136, 1)",
              fontWeight: 500,
              textAlign: "justify",
              marginBottom: "20px",
              [theme.breakpoints.down("sm")]: {
                fontSize: 12,
              },
            }}
          >
            {answer}
          </Typography>
          {/* <Paper
            component="div"
            className={styles.expandCollapseIcon}
            onClick={handleChange("panel1")}
            sx={{
              right: -1,
              transform: "translate(-100%, -50%)",
              boxShadow: "none",
              [theme.breakpoints.down("sm")]: {
                right: 3,
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            {expanded === "panel1" ? (
              <img src={FaqCollapseIcon} alt="collapse icon" />
            ) : null}
          </Paper> */}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default FaqCard
