import React from "react"
import {
  Container,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import * as styles from "./faqs.module.css"
import FaqSearchIcon from "../../../images/faqSearch.svg"
import CloseIcon from "@mui/icons-material/Close"
import FaqCard from "./faqCard"

const faqsData = [
  {
    question: "What is 99rises?",
    answer: (
      <>
        99rises is a managed investment platform that provides low-cost,
        low-minimums access to hedge fund strategies (“alts”) and impact
        investing in separately managed customer accounts. As investment
        advisers, we are a fiduciary, which means we act in your interests ahead
        of our own. We will ask you a bit about yourself, your investment goals
        and set you up for success.
      </>
    ),
  },
  {
    question: `What are “alts”?`,
    answer: (
      <>
        “Alts” or alternative investment strategies offer investors a choice
        over traditional investment strategies that generally allocate capital
        on a “buy and hold” basis (i.e. “long only funds”) to stocks, bonds, and
        cash. “Alts” seek to create positive performance in all market
        conditions over a given investment horizon. As such, often performance
        is not measured versus a broad market index, or a benchmark, but in
        absolute terms along with other factors e.g. volatility.`
      </>
    ),
  },
  {
    question: "How liquid is my capital?",
    answer: (
      <>
        It’s your money in a separately managed account and you can have access
        to it within 3-5 business days. At 99rises, we leverage technology to
        eliminate barriers to most people that may have had reason in the past
        including “locking up” your capital.
      </>
    ),
  },

  {
    question: "How often can I update my portfolio of blocks?",
    answer: (
      <>
        You can change your information as frequently as you like. However,
        after your initial investment, any changes to portfolio allocations will
        be adjusted on the next rebalance (at least monthly).
      </>
    ),
  },
  {
    question: "How secure is my account?",
    answer: (
      <>
        Your assets are held at our custodian, Apex Clearing, one of the premier
        custodians across the brokerage technology industry. We protect your
        personal data and financial information using Secure Sockets Layer (SSL)
        and 256-bit encryption technology.
      </>
    ),
  },
  {
    question: "How do you keep my money safe?",
    answer: (
      <>
        Our brokerage firm, Tradier is a member of FINRA, the securities
        industry self-regulatory body. Your assets are held at our custodian
        Apex Clearing. Each is insured by the SIPC, premier insurer of the
        brokerage industry, up to $500,000 per account, of which $250,000 can be
        applied to cash balances. This can protect against losses of account
        assets in the event that 99rises or Tradier liquidates. What you should
        remember is that this does not protect against market changes or risks
        in your account. The risks inherent in investing are tradeoffs you make
        to pursue investment returns. We design our blocks to aim for the
        highest projected returns given the associated risk level.
      </>
    ),
  },
  {
    question: "How do you decide what block is right for me?",
    answer: (
      <>
        Using guided interviews, our rules-based platform computes a risk score
        and establishes an investment profile that can drive the selection of
        the initial block choices for the customer. At this stage, we are
        focused on picking your initial long-short block which will provide a
        “safety prioritized” core to your portfolio. What we mean by this is a
        block that is broad in its composition and focused on managing
        volatility as current markets make clear can be quite harmful to passive
        strategies. We will expand blocks over time and provide tools for
        evaluating the best choices based on your goals, values, and interests.
      </>
    ),
  },
  {
    question: "What are my risks?",
    answer: (
      <>
        As with most investments, positive returns are not guaranteed and you
        may lose some or all of the money you have invested. Alternative
        strategies are also exposed to the same asset class and market liquidity
        issues as other investments. In addition, although hedging is intended
        to limit or reduce investment risk, it may not achieve that effect. In
        some cases, hedging may even increase losses. Lastly, there are risks
        that will not or cannot be hedged.
      </>
    ),
  },
]

const buildRegEx = (str, keywords) => {
  return new RegExp(
    "(?=.*?\\b" + keywords.split(" ").join(")(?=.*?\\b") + ").*",
    "i"
  )
}

const test = (str, keywords) => {
  return buildRegEx(str, keywords).test(str)
}

const Faqs = () => {
  const theme = useTheme()
  const [searchKeyword, setSearchKeyword] = React.useState("")
  const [faqs, setFaqs] = React.useState(faqsData)
  const [resetExpanded, setResetExpanded] = React.useState(false)

  const searchFaqs = () => {
    const tempFaqs = [...faqsData]
    const searchKeywords = searchKeyword.split(" ")
    const searchResult =
      searchKeyword.length > 0
        ? tempFaqs.filter(
            faq =>
              test(faq.question, searchKeyword) ||
              test(faq.answer, searchKeyword)
          )
        : tempFaqs
    setFaqs([...searchResult])
    setResetExpanded(true)
  }

  React.useEffect(() => {
    if (searchKeyword === "") searchFaqs()
  }, [searchKeyword])

  return (
    <section className={styles.faqSection}>
      <Container maxWidth="md">
        <Typography
          variant="h5"
          sx={{
            fontSize: 16,
            fontFamily: `"Roboto", "sans-serif"`,
            fontWeight: 600,
            marginBottom: "0px",
          }}
        >
          FAQs
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontSize: 32,
            fontFamily: `"Poppins", "sans-serif" `,
            fontWeight: 300,
            marginTop: "10px",
            marginBottom: "10px",
            [theme.breakpoints.down("sm")]: {
              fontSize: 24,
            },
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontFamily: `"Roboto", "sans-serif"`,
            fontWeight: 400,
          }}
        >
          Have a question? We are here to help.
        </Typography>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 500,
            height: 50,
            margin: "20px auto",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
          className={styles.search}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <IconButton
            sx={{
              p: "10px 10px 10px 10px ",
              margin: "auto auto auto 10px",
              [theme.breakpoints.down("sm")]: {
                p: "10px ",
              },
            }}
            aria-label="search"
            onClick={searchFaqs}
          >
            <img src={FaqSearchIcon} alt="search icon" style={{ margin: 0 }} />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: 14, color: "#ffffff" }}
            placeholder="Search"
            inputProps={{ "aria-label": "Search" }}
            className={styles.searchInput}
            value={searchKeyword}
            onChange={e => setSearchKeyword(e.target.value)}
            onKeyUp={e => {
              if (e.key === "Enter") {
                searchFaqs()
              }
            }}
          />
          {searchKeyword.length > 0 ? (
            <IconButton
              sx={{
                p: "10px 10px 10px 10px",
                margin: "auto 10px auto auto",
                [theme.breakpoints.down("sm")]: {
                  p: "10px ",
                },
              }}
              aria-label="search"
              onClick={() => {
                setSearchKeyword("")
              }}
            >
              <CloseIcon sx={{ height: 23, width: 23, color: "#ffffff" }} />
            </IconButton>
          ) : null}
        </Paper>
        <div className={styles.faqsContainer}>
          {faqs.length > 0 ? (
            faqs.map(faq => (
              <FaqCard
                question={faq.question}
                answer={faq.answer}
                resetExpanded={resetExpanded}
                setResetExpanded={setResetExpanded}
              />
            ))
          ) : (
            <Typography className={styles.subHeader}>
              No results found.
            </Typography>
          )}
        </div>
      </Container>
    </section>
  )
}

export default Faqs
