import React, { Component } from "react"
import {
  Box,
  Grid,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material"
import theme from "../theme"

// import phoneHand from "../images/phone-hand.png"
import activeInvesting from "../images/active-investing.gif"
import BroadCatalog from "../images/Icon-trio_442x148px_noira.gif"
//import Betterinvestor from "../images/better-investor.png"
// import safeHand from "../images/safe-hands.png"
//import GreenBg from "../images/green-bg.jpg"
import Green from "../images/green.jpg"
import getStartedImg from "../images/getstartedhere-button_166x166px.gif"
import ManMobileBikeImg from "../images/man-2733048_1280x374px_mobile-bike-v9r3.jpg"
import BannerVideo from "../images/anim-web_99rises_lores2.mp4"
import BannerImage from "../images/anim-web_99rises_lores2.webp"
import GradientBk from "../images/Graystrip_1938x282.gif"

// import Header from "../components/header"
// import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import FooterMenu from "../components/footermenu"
import "../styles/app.scss"
import "../styles/homepage-v1.scss"
import SEO from "../components/seo"

//import phoneFrontImg from "../images/99r-app-front.png"
import phoneFrontImg from "../images/app/99r-phone-home-for-website_352px.gif"
//import phoneHoldingsImg from "../images/99r-APP_Portfolio-ss-1.gif"
import phoneHoldingsImg from "../images/app/99r-phone-portfolio-for-website_352px.gif"
// import phoneStockMemoImg from "../images/99r-app-stockmemo.png"
import phoneStockMemoImg from "../images/app/99r-phone-stock-for-website_352px.gif"

import blockBalancedImg from "../images/bbadges/blocks-Dark-_0001_Balanced_320w.jpg"
import blockOceansImg from "../images/bbadges/blocks-Dark-_0002_Oceans_320w.jpg"
import blockCryptoImg from "../images/bbadges/blocks-Dark-_0003_Crypto_320w.jpg"
import blockSecureImg from "../images/bbadges/blocks-Dark-_0004_Secure_320w.jpg"
import blockGrowthImg from "../images/bbadges/blocks-Dark-_0007_Aggressive_320w.jpg"

import phoneGrowthImg from "../images/iPhone-13-mini-growth.png"

import interviewImg from "../images/chat.png"
import bankLinkImg from "../images/funding.png"
import blockImg from "../images/block.png"

import { Link, useStaticQuery, graphql } from "gatsby"

//import "bootstrap/dist/css/bootstrap.min.css"
import Carousel from "react-bootstrap/Carousel"
import Img from "gatsby-image/withIEPolyfill"
import ArticlesSection from "../components/homepage-v1/articles"
import Faqs from "../components/homepage-v1/faqs"

//import Slider from "react-slick"
//import "slick-carousel/slick/slick.css"
//import "slick-carousel/slick/slick-theme.css"

export default function HomePage3() {
  const getStarted = () => {
    return (
      <div className="circle1">
        <Link
          to="https://wealth.99rises.com/signup"
          rel="nofollow"
          state={{
            modal: true,
          }}
        >
          get started here
        </Link>
      </div>
    )
  }

  const imagesData = useStaticQuery(graphql`
    query homepage3ImagesQuery {
      phoneHandImage: file(name: { eq: "phone-hand" }) {
        name
        relativePath
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iphone13MiniGrowthImage: file(name: { eq: "iPhone-13-mini-growth" }) {
        name
        relativePath
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      safeHands: file(name: { eq: "safe-hands" }) {
        name
        relativePath
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Hedge Fund strategies available to everybody"></SEO>
      <ThemeProvider theme={theme}>
        <div className="homepage">
          <section
            className="hero-banner"
            //style={{ backgroundImage: `url(${BannerAnimatedImg})` }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "320px",
                zIndex: -1,
              }}
            >
              <video
                className="video-player"
                poster={BannerImage}
                width="100%"
                height="320px"
                style={{ objectFit: "fill" }}
                loop
                muted
                autoPlay
              >
                <source src={BannerVideo} type="video/mp4" />
              </video>
            </div>
            <Box width={"100%"} className="hero-box">
              {/* <Box m="auto">Hedge funds for everybody, sanely</Box> */}
              {/* <Box m="auto">Grow your wealth like the rich people do</Box> */}
              <Box m="auto">
                {/* Grow your wealth, <em>safely</em>, in this turbulent world */}
                Grow your wealth in this turbulent world
              </Box>
              {/* <Box m="auto" sx={{ height: "25%", gridArea: "line2" }}>
              Learn along the way with our help
            </Box> */}
            </Box>
          </section>

          <section className="active-platform">
            <Container maxWidth="md">
              <p className="toptitle">
                {/* <b>An active investment</b> platform personalized to you */}
                Invest in your goals, values, and interests
              </p>
              <Grid container>
                <Grid
                  className="grid-display"
                  item
                  xs={12}
                  md={5}
                  sm={5}
                  lg={5}
                  container
                  p={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <img src={phoneHand} width={300} style={{ margin: "auto" }} /> */}
                  <Img
                    fixed={imagesData.phoneHandImage.childImageSharp.fixed}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    alt="phone hand"
                    style={{
                      height: "auto",
                      width: "100%",
                      maxWidth: "300px",
                      aspectRatio: "1",
                    }}
                  />
                </Grid>
                <Grid
                  className="grid-display"
                  item
                  xs={12}
                  md={7}
                  sm={7}
                  lg={7}
                  container
                  p={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div className="description">
                    <ul>
                      {/* <li>
                      Growing your money is a given, but in a way that rings
                      true now and adapts with you?
                    </li> */}
                      <li>
                        Get access to <b>hedge fund strategies</b> available
                        only to the rich before
                      </li>
                      <li>
                        {/* Invest your money in the world you would like to see */}
                        Participate in <b>socially-responsible</b> and{" "}
                        <b>impact investing</b>
                      </li>
                      <li>
                        Lean on our <b>investment team's expertise</b> as your
                        needs and priorities change
                      </li>
                      {/* <li>Get relevant feeds of news and learning</li> */}
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>

          <section>
            <Container maxWidth="md">
              <div className="circle-bar">{getStarted()}</div>
              <div>
                <p className="subtitle">Engage in the journey</p>
              </div>
              <div className="explain-para">
                <p>
                  You'll shape your own wealth-building plans and learn along
                  the way. And we'll actively manage your stock investments,
                  consistently balancing rewards and risks against your goals,
                  values, and interests.
                  {/* You learn along the way and can participate actively in the
                overall strategy and decision-making. And we actively manage
                your portfolio, consistently balancing rewards and risks against
                your goals, values, and interests. */}
                </p>
              </div>
            </Container>
          </section>

          {/* <img src="https://assets.pcmag.com/media/images/544792-ie-turn-off-animations.gif?thumb=y&width=740&height=416" /> */}
          {/* <section className="common-banner">

          <Container
            maxWidth="md"
            style={{
              backgroundImage: `url(${activeInvesting})`,
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid container spacing={4} className="banner-grid">
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                Active Investing for a better future
              </Grid>
            </Grid>
          </Container>
        </section> */}

          <section
            className="common-banner"
            // style={{ backgroundImage: `url(${Green})`, backgroundRepeat: false }}
            style={{
              backgroundImage: `url(${GradientBk})`,
              backgroundRepeat: false,
              backgroundSize: "cover",
            }}
          >
            <Container maxWidth="md">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <div className="black-title">Pick blocks, not stocks</div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <div className="black-em">
                  <p>
                    You pick from a range of blocks including ones we recommend
                    to you based on your profile. We manage the stocks within
                    each block to meet its purpose in your portfolio.
                  </p>
                </div>
              </Grid>
            </Container>
          </section>

          <section className="broad-catalog">
            <Container maxWidth="md">
              {/* <p className="title">Pick blocks, not stocks</p> */}
              {/* <div className="explain-para">
              <p>
                A block is a collection of stocks managed to meet a set of
                objectives related to performance, risk protection, and other
                parameters specific to the block.
              </p>
            </div> */}
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  lg={5}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {/* <BlockCatalog></BlockCatalog> */}
                  {/* <BlockSlider></BlockSlider> */}
                  {/* <img
                  src={phoneGrowthImg}
                  width={250}
                  style={{ marginTop: "25px", marginRight: "25px" }}
                  // style={{ marginBlockEnd: "auto" }}
                /> */}
                  <Img
                    fixed={
                      imagesData.iphone13MiniGrowthImage.childImageSharp.fixed
                    }
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="phone hand"
                    style={{
                      marginTop: "25px",
                      marginRight: "25px",
                      // height: "500px",
                      // width: "250px",
                      height: "auto",
                      width: "100%",
                      maxWidth: "250px",
                      aspectRatio: "1/2",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={7}
                  lg={7}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div>
                    {/* <img src={BroadCatalog} width={300} /> */}
                    <div className="description">
                      A block is a collection of stocks managed to meet a set of
                      objectives related to performance, risk protection, and
                      other parameters specific to the block:
                      <ul>
                        <li>
                          Long-short hedge fund strategies matching your risk
                          profile
                        </li>
                        <li>Purpose-driven investing at low mins </li>
                        <li>
                          Exposure to Crypto and other asset types via ETFs
                        </li>
                        {/* <li>Roth and regular IRA accounts</li>{" "} */}
                      </ul>
                      Blocks provide access to a broad range of strategies and
                      asset classes.
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div></div>

              <div className="explain-para">
                <p>
                  We actively manage the blocks in your portfolio to reduce
                  volatility in chaotic public markets and to seize
                  opportunities as they arise
                </p>
              </div>
            </Container>
          </section>

          <section className="common-banner">
            <Container maxWidth="md">
              <Grid
                container
                className="banner-grid"
                style={{
                  backgroundImage: `url(${ManMobileBikeImg})`,
                  backgroundSize: "100% 160px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  container
                  p={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  Engage and learn as your wealth grows
                </Grid>
                {/* <Grid
                item
                xs={12}
                sm={6}
                lg={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                Engage and ...
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                lg={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                learn as your wealth grows
              </Grid> */}
              </Grid>
            </Container>
            {/* <img src={Betterinvestor} /> */}
          </section>

          <section className="new-experience">
            <Container maxWidth="md">
              <p className="title">An experience that ties learning in</p>
              <Phones3></Phones3>
              <Grid
                container
                lg={12}
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <p className="subtitle">
                    Daily content matching your interests
                  </p>
                  <div className="explain-para">
                    <p>
                      Our goal is to help you understand our strategies and
                      become better investors over time. On any day, you can
                      skim for highlights or you can dig in to the details of
                      our strategies, models, and picks.
                    </p>
                  </div>
                </div>
              </Grid>
            </Container>
          </section>

          <section
            className="enjoy-banner"
            style={{
              backgroundImage: `url(${Green})`,
              backgroundRepeat: false,
            }}
          >
            <Container maxWidth="md">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <div className="white-title">
                  Enjoy the journey toward greater wealth
                </div>
                <div className="white-em">
                  <p>
                    We will be with you every step of the way. Our experience
                    and rigorous investment process enables us to build you a
                    portfolio of blocks that meet your goals, values, and
                    interests in an experience like no other
                  </p>
                </div>
              </Grid>
            </Container>
          </section>

          <section className="greater-wealth">
            <Container maxWidth="md">
              <p className="subtitle">
                It starts with <b>three easy steps</b>
              </p>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={interviewImg} alt="interview icon" />

                  <p>
                    <b>Establish your strategy</b> through a guided interview
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={bankLinkImg} alt="bank link icon" />
                  <p>
                    <b>Fund your account</b> from your bank account
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    className="security"
                    src={blockImg}
                    alt="security icon"
                  />
                  <p>
                    <b>Pick your first block</b> to start the journey
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="circle-bar">{getStarted()}</div>
                </Grid>
              </Grid>
              <div className="fees-minimums">
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    container
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <div>
                      <div className="subhead">Fees & Minimums</div>
                      <p>Minimum $5,000 per account </p>
                      <p>1% annual advisory fee</p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    container
                    direction="row"
                    justifyContent="flex-start"
                  >
                    <div>
                      <div className="subhead">No lock-ups</div>
                      <p style={{ maxWidth: "250px" }}>
                        It’s your money and you can withdraw any time
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </section>

          <section
            className="safe-hands"
            // style={{ backgroundImage: `url(${Green})` }}
            style={{
              background: `linear-gradient(90deg, #1da332 0%, #03935d 101.32%)`,
              backgroundRepeat: false,
              backgroundSize: "cover",
            }}
          >
            <Container maxWidth="md" sx={{ padding: "10px" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  lg={6}
                  container
                  p={4}
                  sx={{
                    direction: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    [theme.breakpoints.down("sm")]: {
                      direction: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: 0,
                    },
                  }}
                >
                  <div>
                    <ul
                      style={{
                        textAlign: "center",
                        fontSize: ".9em",
                        color: "#fff",
                      }}
                    >
                      <li>High standards of security and protection</li>
                      <li>Two-factor login with 256-bit encryption</li>
                      <li>SIPC insurance per account up to $500,000</li>
                    </ul>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  container
                  p={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <img
                  src={safeHand}
                  width={150}
                  style={{ marginLeft: "2.5em", margin: "1em" }}
                /> */}
                  <Img
                    fixed={imagesData.safeHands.childImageSharp.fixed}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="safe hands"
                    style={{
                      height: "auto",
                      width: "100%",
                      maxWidth: "150px",
                      aspectRatio: "1",
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </section>
          <ArticlesSection />
          <Faqs />
        </div>
      </ThemeProvider>
    </Layout>
  )
}

function Phones3() {
  return (
    <Grid container>
      <Grid
        item
        xs={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={phoneFrontImg}
          width={200}
          style={{ marginBlockEnd: "auto" }}
        />
        <p style={{ maxWidth: "200px", textAlign: "center", marginTop: "1em" }}>
          Personalized news and learning feeds
        </p>
      </Grid>
      <Grid
        item
        xs={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={phoneHoldingsImg}
          width={200}
          style={{ marginBlockEnd: "auto" }}
        />
        <p style={{ maxWidth: "200px", textAlign: "center", marginTop: "1em" }}>
          Strategies and stock picks at your fingertips
        </p>
      </Grid>
      <Grid
        item
        xs={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={phoneStockMemoImg}
          width={200}
          style={{ marginBlockEnd: "auto" }}
        />
        <p style={{ maxWidth: "200px", textAlign: "center", marginTop: "1em" }}>
          Explanations that teach as your wealth grows
        </p>
      </Grid>
    </Grid>
  )
}

function BlockCatalog() {
  return (
    <div style={{ height: "480px", width: "320px", textAlign: "center" }}>
      <Carousel interval="4000" controls={false}>
        <Carousel.Item>
          <div className="block-badge">
            <img
              className="d-block w-100"
              src={blockBalancedImg}
              alt="First slide"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="block-badge">
            <img
              className="d-block w-100"
              src={blockSecureImg}
              alt="Second slide"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="block-badge">
            <img
              src={blockGrowthImg}
              className="d-block w-100"
              alt="Third slide"
            />
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}
