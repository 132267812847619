import React, { useState } from "react"
import { Box, Button, Container, Typography, useTheme } from "@mui/material"
import Slider from "react-slick"
import * as styles from "./articles.module.css"
import ArticlesCarousel from "./articlesCarousel"
import LeftArrow from "./leftArrow"
import RightArrow from "./rightArrow"
import { Link } from "gatsby"

const ArticlesSection = () => {
  const theme = useTheme()
  const [slideIndex, setSlideIndex] = useState(0)

  return (
    <section className={styles.articleSection}>
      <Container maxWidth="md">
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: 32,
              fontFamily: `"Poppins", "sans-serif" `,
              fontWeight: 300,
              marginTop: "10px",
              marginBottom: "10px",
              [theme.breakpoints.down("sm")]: {
                fontSize: 24,
              },
            }}
          >
            Our Articles
          </Typography>
          <Box className={styles.carouselArrowsDesktop}>
            <LeftArrow
              onClick={() => {
                setSlideIndex(-1)
              }}
            />
            <RightArrow
              onClick={() => {
                setSlideIndex(1)
              }}
            />
          </Box>
        </Box>
      </Container>
      <ArticlesCarousel slideIndex={slideIndex} setSlideIndex={setSlideIndex} />
      <Container maxWidth="md">
        <Box className={styles.carouselArrowsMobile}>
          <LeftArrow
            onClick={() => {
              setSlideIndex(-1)
            }}
          />
          <RightArrow
            onClick={() => {
              setSlideIndex(1)
            }}
          />
        </Box>
        <Link className={styles.viewMoreLink} to="/blog">
          <Button
            variant="outlined"
            sx={{
              color: "#636363",
              fontSize: "14px",
              fontFamily: `"Poppins", "sans-serif" `,
              textTransform: "capitalize",
              border: "1px solid #636363",
              borderRadius: "0",
              minHeight: "40px",
              minWidth: "140px",
              marginBottom: "10px",
              textDecoration: "none",
            }}
            className={styles.viewMoreButton}
          >
            View More
          </Button>
        </Link>
      </Container>
    </section>
  )
}

export default ArticlesSection
