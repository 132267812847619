import { StylesProvider } from "@material-ui/core"
import React from "react"
import * as styles from "./articles.module.css"

function LeftArrow({ style, onClick }) {
  return (
    <div
      className={styles.leftArrow}
      id="left-arrow-slick-about"
      style={{
        ...style,
        position: "relative",
        // top: "-220px",
        // transform: "translateX(150%) translateY(200%)",
        // right: "215px",
        width: "44px",
        height: "44px",
        cursor: "pointer",
        backgroundColor: "transparent",
        zIndex: "10",
        borderRadius: "100%",
        border: "1.5px solid #b5b5b5",
      }}
      onClick={onClick}
    >
      <svg
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-55%) translateY(-50%)",
        }}
      >
        <path
          d="M10.3773 0.202148C10.7372 0.313842 11.0184 0.50244 11.1261 0.857662C11.1674 0.987595 11.1742 1.12473 11.146 1.25759C11.1177 1.39044 11.0551 1.51513 10.9636 1.62121C10.8979 1.69765 10.8263 1.76956 10.7492 1.83636L3.09865 8.86207C3.05079 8.9051 3.00592 8.95362 2.91618 9.03785C2.98422 9.07264 3.04863 9.1131 3.10862 9.1587C5.66976 11.5061 8.22892 13.8559 10.7861 16.2082C11.1729 16.5589 11.2627 16.9452 11.0513 17.3224C10.9874 17.4359 10.8974 17.5354 10.7875 17.6138C10.6776 17.6923 10.5504 17.7479 10.4149 17.7768C10.2793 17.8057 10.1385 17.8072 10.0022 17.7812C9.86587 17.7553 9.73735 17.7024 9.62548 17.6264C9.54652 17.5705 9.4722 17.5093 9.40314 17.4433C6.60003 14.8725 3.7966 12.2992 0.992827 9.72358C0.469364 9.24293 0.469364 8.75587 0.992827 8.27522C3.74874 5.75021 6.50298 3.22307 9.25557 0.693784C9.47692 0.490538 9.69727 0.293701 10.0034 0.202148H10.3773Z"
          fill="#999595"
        />
      </svg>
    </div>
  )
}

export default LeftArrow
